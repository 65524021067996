import { updateTextQtdIconMobile } from './carrinho.js';
import { reloadicons } from './svg.js';
import { closeNav, openNav } from './utilities.js';

$(document).ready(function () {
	updateTextQtdIconMobile();

	$(document).on("click touchend", ".nav-footer h4", function (e) {
		e.preventDefault();
		var aberto = $(this).find("a.setacima");
		var fechado = $(this).find("a.setabaixo");
		var conteudo = $(this).parent().find("ul");
		if (fechado.length == 1) {
			fechado.removeClass("setabaixo");
			fechado.addClass("setacima");
			if(conteudo.hasClass("bandeiras-pagamentos")){
				conteudo.css("display", "flex");
			}else{
				conteudo.css("display", "block");
			}
			reloadicons("setacima");
		}
		if (aberto.length == 1) {
			aberto.removeClass("setacima");
			aberto.addClass("setabaixo");
			conteudo.css("display", "none");
			reloadicons("setabaixo");
		}
	});
	$(document).on("click touchend", "#mobile-headerLine ul li a.navbar-toggle, #mobileMenu .iconclose", function (e) {
		e.preventDefault();
		e.stopImmediatePropagation();
		if ($(this).hasClass("collapsed")) {
			closeAllMenus();
			openNavMobile();
			//$(this).removeClass("collapsed");
		} else {
			closeNavMobile();
			//$(this).addClass("collapsed")
		}
	});

	$(document).on("click touchend", "#mobile-headerLine ul li a.mobile-loginIcon, #mobileUserMenu .iconclose", function (e) {
		e.preventDefault();
		e.stopImmediatePropagation();
		if ($(this).hasClass("collapsed")) {
			closeAllMenus();
			openNavUserMobile();
			//$(this).removeClass("collapsed");
		} else {
			closeNavUserMobile();
			//$(this).addClass("collapsed")
		}
	});

	$(document).on("click touchend", ".category-sub-menu .filters-toggle, .category-filters-lateral .iconclose", function (e) {
		e.preventDefault();
		if ($(".category-sub-menu .category-filters-lateral").hasClass("collapsed")) {
			closeAllMenus();
			openFilterMobile();
		} else {
			closeFilterMobile();
		}
	});
	// Menu institucional
	$(document).on("click touchend", ".menu-suporte .menuInst-toggle, .category-menuInst-lateral .iconclose", function (e) {
		e.preventDefault();
		if ($(".menu-suporte .category-menuInst-lateral").hasClass("collapsed")) {
			closeAllMenus();
			openMenuInstMobile();
		} else {
			closeMenuInstMobile();
		}
	});
	$(document).on("click touchend", ".category-sub-menu .orders-toggle, .category-orderby-lateral .iconclose", function (e) {
		e.preventDefault();
		if ($(".category-sub-menu .category-orderby-lateral").hasClass("collapsed")) {
			closeAllMenus();
			openOrderbyMobile();
		} else {
			closeOrderbyMobile();
		}
	});

	$(document).on("click touchstart", "#mobileMenu .mm-next", function (e) {
		e.preventDefault();
		var atual = $(this).attr("data-mmatual");
		var pai = $(this).attr("data-mmpai");
		var filho = $(this).attr("data-mmfilho");
		$("#mobileMenu #" + atual).removeClass("mm-opened");
		$("#mobileMenu #" + atual).addClass("mm-waiting");
		$("#mobileMenu #" + atual).one("webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend", function (event) {
			$("#mobileMenu #" + atual).addClass("mm-hidden");
			$("#mobileMenu #" + atual).off("webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend");
		});
		$("#mobileMenu #" + filho).removeClass("mm-hidden");
		var maxTime = 1000, startTime = Date.now();
		var interval = setInterval(function () {
			if ($("#mobileMenu #" + filho).is(':visible')) {
				$("#mobileMenu #" + filho).addClass("mm-opened");
				clearInterval(interval);
			} else {
				if (Date.now() - startTime > maxTime) {
					$("#mobileMenu #" + filho).addClass("mm-opened");
					clearInterval(interval);
				}
			}
		},
			100
		);
	});

	$(document).on("click touchstart", "#mobileMenu .mm-prev", function (e) {
		e.preventDefault();
		var atual = $(this).attr("data-mmatual");
		var pai = $(this).attr("data-mmpai");
		$("#mobileMenu #" + atual).removeClass("mm-opened");
		$("#mobileMenu #" + atual).one("webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend", function (event) {
			$("#mobileMenu #" + atual).addClass("mm-hidden");
			$("#mobileMenu #" + atual).off("webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend");
		});
		$("#mobileMenu #" + pai).removeClass("mm-hidden");
		var maxTime = 1000, startTime = Date.now();
		var interval = setInterval(function () {
			if ($("#mobileMenu #" + pai).is(':visible')) {
				$("#mobileMenu #" + pai).addClass("mm-opened");
				$("#mobileMenu #" + pai).removeClass("mm-waiting");
				clearInterval(interval);
			} else {
				if (Date.now() - startTime > maxTime) {
					$("#mobileMenu #" + pai).addClass("mm-opened");
					$("#mobileMenu #" + pai).removeClass("mm-waiting");
					clearInterval(interval);
				}
			}
		},
			100
		);
	});

	$(document).on("click", "#mobile-headerLine .search-open", function () {
		$("#mobile-headerSearch").removeClass("displaynone");
	});

	$('#mobile-headerSearch .search-close').on("click", function (e) {
		e.stopPropagation();
		e.preventDefault();

		$("#mobile-headerSearch").addClass("displaynone");
	});

	$("#mobile-headerLine .btn-cart").on("click", function (e) {
		e.stopPropagation();
		e.preventDefault();

		openNav();
	});
});

/* mobileMenu */
export function openNavMobile() {
	$("#mobileMenu").addClass("mobilemenu-aberto");
	$("#main-content").addClass("mobilemenu-aberto");
	$("body").addClass("aberto");
	if ($("#mobile-headerLine ul li a.navbar-toggle").hasClass("collapsed")) {
		$("#mobile-headerLine ul li a.navbar-toggle").removeClass("collapsed");
	}
	$("#overlay-geral").addClass("active");
}

export function closeNavMobile() {
	$("#mobileMenu").removeClass("mobilemenu-aberto");
	$("#main-content").removeClass("mobilemenu-aberto");
	$("body").removeClass("aberto");
	if (!$("#mobile-headerLine ul li a.navbar-toggle").hasClass("collapsed")) {
		$("#mobile-headerLine ul li a.navbar-toggle").addClass("collapsed");
	}
	$("#mobileMenu .mm-panel").addClass("mm-hidden");
	$("#mobileMenu .mm-panel").removeClass("mm-waiting");
	$("#mobileMenu #mm-0").removeClass("mm-hidden");
	$("#mobileMenu #mm-0").addClass("mm-opened");
	$("#overlay-geral").removeClass("active");
}

export function openNavUserMobile() {
	$("#mobileUserMenu").addClass("mobileusermenu-aberto");
	$("#main-content").addClass("mobilemenu-aberto");
	$("body").addClass("aberto");
	if ($("#mobile-headerLine ul li a.mobile-loginIcon").hasClass("collapsed")) {
		$("#mobile-headerLine ul li a.mobile-loginIcon").removeClass("collapsed");
	}
	$("#overlay-geral").addClass("active");
}

export function closeNavUserMobile() {
	$("#mobileUserMenu").removeClass("mobileusermenu-aberto");
	$("#main-content").removeClass("mobilemenu-aberto");
	$("body").removeClass("aberto");
	if (!$("#mobile-headerLine ul li a.mobile-loginIcon").hasClass("collapsed")) {
		$("#mobile-headerLine ul li a.mobile-loginIcon").addClass("collapsed");
	}
	$("#overlay-geral").removeClass("active");
}


export function openFilterMobile() {
	$(".category-sub-menu .category-filters-lateral").removeClass("collapsed");
	$("#main-content").addClass("mobilemenu-aberto");
	$("body").addClass("aberto");
	$("#overlay-geral").addClass("active");
}
/* Menu Institucional */
export function openMenuInstMobile() {
	$(".menu-suporte .category-menuInst-lateral").removeClass("collapsed");
	$("#main-content").addClass("mobilemenu-aberto");
	$("body").addClass("aberto");
	$("#overlay-geral").addClass("active");
}
/* Menu Institucional */

export function closeFilterMobile() {
	$("#main-content").removeClass("mobilemenu-aberto");
	$("body").removeClass("aberto");
	if (!$(".category-sub-menu .category-filters-lateral").hasClass("collapsed")) {
		$(".category-sub-menu .category-filters-lateral").addClass("collapsed");
	}
	$("#overlay-geral").removeClass("active");
}
/* Menu Institucional */
export function closeMenuInstMobile() {
	$("#main-content").removeClass("mobilemenu-aberto");
	$("body").removeClass("aberto");
	if (!$(".menu-suporte .category-menuInst-lateral").hasClass("collapsed")) {
		$(".menu-suporte .category-menuInst-lateral").addClass("collapsed");
	}
	$("#overlay-geral").removeClass("active");
}
/* Menu Institucional */
export function openOrderbyMobile() {
	$(".category-sub-menu .category-orderby-lateral").removeClass("collapsed");
	$("#main-content").addClass("mobilemenu-aberto");
	$("body").addClass("aberto");
	$("#overlay-geral").addClass("active");
}

export function closeOrderbyMobile() {
	$("#main-content").removeClass("mobilemenu-aberto");
	$("body").removeClass("aberto");
	if (!$(".category-sub-menu .category-orderby-lateral").hasClass("collapsed")) {
		$(".category-sub-menu .category-orderby-lateral").addClass("collapsed");
	}
	$("#overlay-geral").removeClass("active");
}

export function closeAllMenus() {
	closeNav();
	closeNavMobile();
	closeNavUserMobile();
	closeFilterMobile();
	closeOrderbyMobile();
	closeMenuInstMobile();
}